var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-card-actions', {
    attrs: {
      "title": "Filters",
      "action-collapse": ""
    }
  }, [_c('div', [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "6",
      "lg": "6",
      "xs": "12"
    }
  }, [_c('b-form-input', {
    staticClass: "d-inline-block mr-sm-1 mb-1 mb-sm-0",
    attrs: {
      "placeholder": "Search By Invoice ID",
      "type": "text"
    },
    on: {
      "keyup": _vm.onSearch
    },
    model: {
      value: _vm.searchTerm,
      callback: function callback($$v) {
        _vm.searchTerm = $$v;
      },
      expression: "searchTerm"
    }
  })], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "6",
      "xs": "12"
    }
  }, [_c('v-select', {
    staticClass: "mr-sm-1 mb-1 mb-sm-0 custom-font",
    attrs: {
      "label": "name",
      "options": _vm.statusTypeOptions,
      "reduce": function reduce(item) {
        return item.value;
      },
      "placeholder": "By Status"
    },
    on: {
      "input": _vm.filterByStatus
    },
    model: {
      value: _vm.filterStatus,
      callback: function callback($$v) {
        _vm.filterStatus = $$v;
      },
      expression: "filterStatus"
    }
  })], 1)], 1)], 1)]), _c('b-card', [_c('div', [_c('vue-good-table', {
    attrs: {
      "mode": "remote",
      "styleClass": "vgt-table table-custom-style striped",
      "totalRows": _vm.totalRecords,
      "isLoading": _vm.isLoading,
      "rows": _vm.rows,
      "sort-options": {
        enabled: false,
        multipleColumns: true,
        initialSortBy: [{
          field: 'id',
          type: 'desc'
        }, {
          field: 'created_at',
          type: 'desc'
        }]
      },
      "columns": _vm.columns,
      "pagination-options": {
        enabled: true,
        perPage: _vm.pageLength
      }
    },
    on: {
      "on-page-change": _vm.onPageChange,
      "on-sort-change": _vm.onSortChange,
      "on-column-filter": _vm.onColumnFilter,
      "on-per-page-change": _vm.onPerPageChange,
      "update:isLoading": function updateIsLoading($event) {
        _vm.isLoading = $event;
      },
      "update:is-loading": function updateIsLoading($event) {
        _vm.isLoading = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "table-row",
      fn: function fn(props) {
        var _props$column, _props$row, _props$row2, _props$row3, _props$column2, _props$row4, _props$row4$milestone, _props$row5, _props$row5$milestone, _props$row5$milestone2, _props$row6, _props$row6$milestone, _props$row6$milestone2, _props$row7, _props$row7$milestone, _props$row7$milestone2, _props$row8, _props$row8$milestone, _props$row8$milestone2, _props$row9, _props$row9$milestone, _props$row9$milestone2, _props$row10, _props$row10$mileston, _props$row10$mileston2, _props$row11, _props$row11$mileston, _props$row11$mileston2, _props$column3, _props$row12, _props$column4, _props$row13, _props$row14, _props$row15, _props$row16, _props$row17, _props$row18, _props$row19;
        return [(props === null || props === void 0 ? void 0 : (_props$column = props.column) === null || _props$column === void 0 ? void 0 : _props$column.field) === 'invoice_info' ? [_c('div', {
          staticClass: "table-cell-margin"
        }, [_c('b-link', [_vm._v(_vm._s(props === null || props === void 0 ? void 0 : (_props$row = props.row) === null || _props$row === void 0 ? void 0 : _props$row.invoice_no))])], 1), _c('div', {
          staticClass: "table-cell-margin"
        }, [_vm._v(" Issue Date: "), _c('b', [_c('span', [_vm._v(_vm._s(_vm.formatDate(props === null || props === void 0 ? void 0 : (_props$row2 = props.row) === null || _props$row2 === void 0 ? void 0 : _props$row2.issue_date)))])])]), _c('div', {
          staticClass: "table-cell-margin"
        }, [_vm._v(" Due Date: "), _c('b', [_c('span', [_vm._v(_vm._s(_vm.formatDate(props === null || props === void 0 ? void 0 : (_props$row3 = props.row) === null || _props$row3 === void 0 ? void 0 : _props$row3.due_date)))])])])] : _vm._e(), (props === null || props === void 0 ? void 0 : (_props$column2 = props.column) === null || _props$column2 === void 0 ? void 0 : _props$column2.field) === 'milestone_info' ? [props !== null && props !== void 0 && (_props$row4 = props.row) !== null && _props$row4 !== void 0 && (_props$row4$milestone = _props$row4.milestone) !== null && _props$row4$milestone !== void 0 && _props$row4$milestone.data ? [_c('div', {
          staticClass: "table-cell-margin"
        }, [_c('b-link', [_vm._v(_vm._s(props === null || props === void 0 ? void 0 : (_props$row5 = props.row) === null || _props$row5 === void 0 ? void 0 : (_props$row5$milestone = _props$row5.milestone) === null || _props$row5$milestone === void 0 ? void 0 : (_props$row5$milestone2 = _props$row5$milestone.data) === null || _props$row5$milestone2 === void 0 ? void 0 : _props$row5$milestone2.title))])], 1), _c('div', {
          staticClass: "table-cell-margin"
        }, [_vm._v(" Amount: "), _c('b', [_c('FormatCurrency', {
          attrs: {
            "amount": props === null || props === void 0 ? void 0 : (_props$row6 = props.row) === null || _props$row6 === void 0 ? void 0 : (_props$row6$milestone = _props$row6.milestone) === null || _props$row6$milestone === void 0 ? void 0 : (_props$row6$milestone2 = _props$row6$milestone.data) === null || _props$row6$milestone2 === void 0 ? void 0 : _props$row6$milestone2.total_amount,
            "currency": props === null || props === void 0 ? void 0 : (_props$row7 = props.row) === null || _props$row7 === void 0 ? void 0 : (_props$row7$milestone = _props$row7.milestone) === null || _props$row7$milestone === void 0 ? void 0 : (_props$row7$milestone2 = _props$row7$milestone.data) === null || _props$row7$milestone2 === void 0 ? void 0 : _props$row7$milestone2.currency
          }
        })], 1)]), _c('div', {
          staticClass: "table-cell-margin"
        }, [_vm._v(" VAT: "), _c('b', [_c('FormatCurrency', {
          attrs: {
            "amount": props === null || props === void 0 ? void 0 : (_props$row8 = props.row) === null || _props$row8 === void 0 ? void 0 : (_props$row8$milestone = _props$row8.milestone) === null || _props$row8$milestone === void 0 ? void 0 : (_props$row8$milestone2 = _props$row8$milestone.data) === null || _props$row8$milestone2 === void 0 ? void 0 : _props$row8$milestone2.total_vat_amount,
            "currency": props === null || props === void 0 ? void 0 : (_props$row9 = props.row) === null || _props$row9 === void 0 ? void 0 : (_props$row9$milestone = _props$row9.milestone) === null || _props$row9$milestone === void 0 ? void 0 : (_props$row9$milestone2 = _props$row9$milestone.data) === null || _props$row9$milestone2 === void 0 ? void 0 : _props$row9$milestone2.currency
          }
        })], 1)]), _c('div', {
          staticClass: "table-cell-margin"
        }, [_vm._v(" TAX: "), _c('b', [_c('FormatCurrency', {
          attrs: {
            "amount": props === null || props === void 0 ? void 0 : (_props$row10 = props.row) === null || _props$row10 === void 0 ? void 0 : (_props$row10$mileston = _props$row10.milestone) === null || _props$row10$mileston === void 0 ? void 0 : (_props$row10$mileston2 = _props$row10$mileston.data) === null || _props$row10$mileston2 === void 0 ? void 0 : _props$row10$mileston2.total_tax_amount,
            "currency": props === null || props === void 0 ? void 0 : (_props$row11 = props.row) === null || _props$row11 === void 0 ? void 0 : (_props$row11$mileston = _props$row11.milestone) === null || _props$row11$mileston === void 0 ? void 0 : (_props$row11$mileston2 = _props$row11$mileston.data) === null || _props$row11$mileston2 === void 0 ? void 0 : _props$row11$mileston2.currency
          }
        })], 1)])] : _vm._e()] : _vm._e(), (props === null || props === void 0 ? void 0 : (_props$column3 = props.column) === null || _props$column3 === void 0 ? void 0 : _props$column3.field) === 'format_currency' ? [_c('FormatCurrency', {
          attrs: {
            "type": 'currency',
            "currency": props === null || props === void 0 ? void 0 : (_props$row12 = props.row) === null || _props$row12 === void 0 ? void 0 : _props$row12.currency
          }
        })] : _vm._e(), (props === null || props === void 0 ? void 0 : (_props$column4 = props.column) === null || _props$column4 === void 0 ? void 0 : _props$column4.field) === 'amount_info' ? [_c('div', {
          staticClass: "table-cell-margin"
        }, [_vm._v(" Sub Total Amount: "), _c('b', [_c('span', [_vm._v(_vm._s(props === null || props === void 0 ? void 0 : (_props$row13 = props.row) === null || _props$row13 === void 0 ? void 0 : _props$row13.sub_total_amount))])])]), _c('div', {
          staticClass: "table-cell-margin"
        }, [_vm._v(" VAT Amount: "), _c('b', [_c('span', [_vm._v(_vm._s(props === null || props === void 0 ? void 0 : (_props$row14 = props.row) === null || _props$row14 === void 0 ? void 0 : _props$row14.vat_amount))])])]), _c('div', {
          staticClass: "table-cell-margin"
        }, [_vm._v(" TAX Amount: "), _c('b', [_c('span', [_vm._v(_vm._s(props === null || props === void 0 ? void 0 : (_props$row15 = props.row) === null || _props$row15 === void 0 ? void 0 : _props$row15.tax_amount))])])]), _c('div', {
          staticClass: "table-cell-margin"
        }, [_vm._v(" Grand Total Amount: "), _c('b', [_c('span', [_vm._v(_vm._s(props === null || props === void 0 ? void 0 : (_props$row16 = props.row) === null || _props$row16 === void 0 ? void 0 : _props$row16.grand_total_amount) + " ")])])])] : _vm._e(), props.column.field === 'status_info' ? [(props === null || props === void 0 ? void 0 : (_props$row17 = props.row) === null || _props$row17 === void 0 ? void 0 : _props$row17.status) == 'paid' ? _c('b-badge', {
          attrs: {
            "variant": "light-success"
          }
        }, [_vm._v(" Paid ")]) : _c('b-badge', {
          attrs: {
            "variant": "light-danger"
          }
        }, [_vm._v(" Due ")])] : _vm._e(), props.column.field === 'action' ? _c('span', [_c('span', [_c('b-dropdown', {
          attrs: {
            "variant": "link",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('feather-icon', {
                staticClass: "text-body align-middle mr-25",
                attrs: {
                  "icon": "MoreVerticalIcon",
                  "size": "16"
                }
              })];
            },
            proxy: true
          }], null, true)
        }, [[_c('b-dropdown-item', {
          on: {
            "click": function click($event) {
              return _vm.onShowPage(props.row);
            }
          }
        }, [(props === null || props === void 0 ? void 0 : (_props$row18 = props.row) === null || _props$row18 === void 0 ? void 0 : _props$row18.status) == 'paid' ? [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "EyeIcon"
          }
        }), _c('span', [_vm._v("Show")])] : _vm._e(), (props === null || props === void 0 ? void 0 : (_props$row19 = props.row) === null || _props$row19 === void 0 ? void 0 : _props$row19.status) == 'due' ? [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "Edit2Icon"
          }
        }), _c('span', [_vm._v("Edit")])] : _vm._e()], 2)], [_c('b-dropdown-item', {
          on: {
            "click": function click($event) {
              return _vm.onDelete(props.row.id);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "TrashIcon"
          }
        }), _c('span', [_vm._v("Delete")])], 1)]], 2)], 1)]) : _c('span', [_vm._v(" " + _vm._s(props.formattedRow[props.column.field]) + " ")])];
      }
    }, {
      key: "pagination-bottom",
      fn: function fn(props) {
        return [_c('div', {
          staticClass: "d-flex justify-content-between flex-wrap"
        }, [_c('div', {
          staticClass: "d-flex align-items-center mb-0 mt-1"
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" Showing 1 to ")]), _c('b-form-select', {
          staticClass: "mx-1",
          attrs: {
            "options": ['10', '25', '50', '100', '500']
          },
          on: {
            "input": function input(value) {
              return props.perPageChanged({
                currentPerPage: value
              });
            }
          },
          model: {
            value: _vm.pageLength,
            callback: function callback($$v) {
              _vm.pageLength = $$v;
            },
            expression: "pageLength"
          }
        }), _c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" of " + _vm._s(props.total) + " entries ")])], 1), _c('div', [_c('b-pagination', {
          staticClass: "mt-1 mb-0",
          attrs: {
            "value": 1,
            "total-rows": props.total,
            "per-page": _vm.pageLength,
            "first-number": "",
            "last-number": "",
            "align": "right",
            "prev-class": "prev-item",
            "next-class": "next-item"
          },
          on: {
            "input": function input(value) {
              return props.pageChanged({
                currentPage: value
              });
            }
          },
          scopedSlots: _vm._u([{
            key: "prev-text",
            fn: function fn() {
              return [_c('feather-icon', {
                attrs: {
                  "icon": "ChevronLeftIcon",
                  "size": "18"
                }
              })];
            },
            proxy: true
          }, {
            key: "next-text",
            fn: function fn() {
              return [_c('feather-icon', {
                attrs: {
                  "icon": "ChevronRightIcon",
                  "size": "18"
                }
              })];
            },
            proxy: true
          }], null, true)
        })], 1)])];
      }
    }])
  })], 1), _c('b-modal', {
    attrs: {
      "id": "modal-division-form",
      "centered": "",
      "title": _vm.modelType == 'editModel' ? 'Edit' : 'Create',
      "hide-footer": "",
      "no-close-on-backdrop": ""
    },
    on: {
      "hidden": _vm.hiddenModal
    }
  }, [_c('validation-observer', {
    ref: "divisionValidation"
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.validationForm($event);
      }
    }
  }, [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Name",
      "label-for": "name"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "name",
      "vid": "name",
      "rules": "required|max:255"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "name",
            "type": "text",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Division"
          },
          model: {
            value: _vm.name,
            callback: function callback($$v) {
              _vm.name = $$v;
            },
            expression: "name"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Head",
      "label-for": "head-id"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "head_id",
      "vid": "head_id"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('v-select', {
          attrs: {
            "id": "head-id",
            "placeholder": "Choose a Head",
            "options": _vm.headIdOption,
            "reduce": function reduce(country) {
              return country.id;
            },
            "label": "name"
          },
          model: {
            value: _vm.selectHeadId,
            callback: function callback($$v) {
              _vm.selectHeadId = $$v;
            },
            expression: "selectHeadId"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _vm.isDivisionFormSubmitLoading ? [_c('b-button', {
    staticClass: "float-right",
    attrs: {
      "variant": "primary",
      "disabled": ""
    }
  }, [_c('b-spinner', {
    attrs: {
      "small": ""
    }
  }), _vm._v(" Loading... ")], 1)] : [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "float-right",
    attrs: {
      "type": "submit",
      "variant": "primary"
    }
  }, [_vm._v(" Submit ")])]], 2)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }